import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import axios from 'axios';
import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../Loader/Spinner';

const TransferWalletAmount = ({ API_URL, token, user }) => {
    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = useState('');
    const [isLoading, setIsLoding] = useState(false);
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');
    const [tPin, setTpin] = useState('');
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        // fetchData();
    }, [API_URL, token]);

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     if (!breakPaymentAmount || !transactionId) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Invalid input',
    //             text: `Either break amount or transaction ID are empty.`,
    //             confirmButtonColor: '#F7931A'
    //         });
    //         return;
    //     }

    //     try {
    //         const data = {
    //             breakPaymentId: id,
    //             breakPaymentAmount,
    //             transactionId
    //         };

    //         const config = {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         };

    //         const res = await axios.post(`${API_URL}/api/plan/complete-payment`,
    //             qs.stringify(data),
    //             config
    //         );

    //         if (res.status === 200) {
    //             setIsLoding(false);
    //         }

    //         Swal.fire({
    //             icon: 'success',
    //             title: 'Break amount updation request raised.',
    //             text: 'Plan activation can take 24hrs to 48hrs upon successful payment validation. If your Plan is not activated in a given time, kindly reach us with UPI transaction ID on our helpdesk email id.',
    //             confirmButtonColor: '#F7931A'
    //         }).then(() => {
    //             let timerInterval;

    //             // Show loader with a countdown timer for 4 seconds
    //             Swal.fire({
    //                 title: 'Redirecting...',
    //                 html: 'Redirecting in <b></b> seconds.',
    //                 timer: 4000,
    //                 timerProgressBar: true,
    //                 showConfirmButton: false,
    //                 allowOutsideClick: false,
    //                 willOpen: () => {
    //                     Swal.showLoading();
    //                     const b = Swal.getHtmlContainer().querySelector('b');
    //                     timerInterval = setInterval(() => {
    //                         b.textContent = Math.ceil(Swal.getTimerLeft() / 1000);
    //                     }, 100);
    //                 },
    //                 willClose: () => {
    //                     clearInterval(timerInterval);
    //                 }
    //             }).then(() => {
    //                 if (res.status === 200) {
    //                     navigate(`/portfolio`);
    //                 } else {
    //                     navigate('/');
    //                 }
    //             });
    //         });


    //     } catch (err) {
    //         console.log(err);

    //         setIsLoding(false);
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error',
    //             text: err?.response?.data?.message || 'Something went wrong. Please try again!',
    //             confirmButtonColor: '#F7931A'
    //         });
    //     }
    // }

    const handleTpinChange = (e) => {
        if (e.target.value.length > 5) {
            setError('Transaction Pin should be in 5 digits');
        }
        if (!e.target.value) {
            setError('');
        }
        setTpin(e.target.value);
    }

    const handleCreateTpin = async (e) => {
        e.preventDefault();

        if (!tPin) {
            setError('Enter a 5 digit Tpin');
            return;
        }

        try {
            setIsLoding(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const res = await axios.post(`${API_URL}/api/auth/create-tpin`, { tPin }, config);
            if (res.data?.user?.tPin) {
                setShowContent(true);
                setError('');
                setIsLoding(false);
            }
        } catch (error) {
            setShowContent(false);
            setError(error.response?.data?.error || 'Failed to create Tpin');
            setIsLoding(false);
        }
    }

    const handleVerifyTpin = async (e) => {
        e.preventDefault();

        if (!tPin) {
            setError('Enter a 5 digit Tpin');
            return;
        }

        try {
            setIsLoding(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const res = await axios.post(`${API_URL}/api/auth/verify-tpin`, { tPin }, config);
            if (res.data?.user?.tPin) {
                setShowContent(true);
                setError('');
                setIsLoding(false);
            }
        } catch (error) {
            setShowContent(false);
            setError(error.response?.data?.error || 'Tpin verification failed.');
            setIsLoding(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoding(true);
        const indianMobileNumberPattern = /^[6-9]\d{9}$/;

        // Check if the entered mobile number matches the pattern
        if (!indianMobileNumberPattern.test(mobileNumber)) {
            return setError("Enter a valid mobile number.");
        }

        if (!mobileNumber && !amount) {
            return setError('Mobile number and amount are required');
        }

        if (!mobileNumber) {
            return setError('Mobile number is required');
        }

        if (!amount) {
            return setError('Amount is required');
        }

        if (amount > user.totalBonus) {
            return setError('Amount should not be greater than wallet amount');
        }
        setError('');


        try {
            const data = {
                recipientMobile: mobileNumber,
                amount,
            };

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const res = await axios.post(`${API_URL}/api/profile/transfer-wallet-amount`,
                qs.stringify(data),
                config
            );

            if (res.status === 200) {
                setIsLoding(false);
            }

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Your amount has been successfully transfered!',
                confirmButtonColor: '#F7931A'
            }).then(() => {
                if (res.status === 200) {
                    navigate(`/portfolio`);
                } else {
                    navigate('/profile');
                }
            });
        } catch (error) {
            setIsLoding(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `${error?.response?.data?.message || 'Something went wrong. Please try again.'}`,
                confirmButtonColor: '#F7931A'
            });
        }
    }

    const handleNav = () => {
        navigate('/profile');
    }

    const formatCurrency = (value) => {
        const numValue = Number(value); // Convert value to a number
        if (isNaN(numValue)) {
            return '0.00'; // Fallback if value is not a number
        }
        return numValue.toLocaleString('en-IN', { minimumFractionDigits: 2 });
    };

    if (isLoading) {
        <Spinner />
    }

    return (
        <div className='refer-container'>
            <div className='mt-6 flex flex-wrap flex-row items-center justify-between gap-4 bread-nav' onClick={handleNav}>
                <div className='flex flex-wrap flex-row items-center justify-center'>
                    <FontAwesomeIcon icon={faAngleLeft} />
                    <div className='ml-4'>
                        Back
                    </div>
                </div>
            </div>
            {
                !showContent && (
                    <>
                        {
                            user.tPin ? (
                                <div>
                                    <h2 className='mt-10 text-center'>Enter Transaction Pin</h2>
                                    <form onSubmit={handleVerifyTpin} className='flex flex-col justify-center items-center gap-1 mt-4 mb-8'>
                                        <input
                                            type="text"
                                            name='tPin'
                                            value={tPin}
                                            onChange={handleTpinChange}
                                            maxLength={5}
                                            className='mobile-number-input'
                                        />
                                        {error && <p className='mt-4 text-center text-red-600'>{error}</p>}
                                        <button type="submit" className='primary-button primary-btn mt-4' disabled={!tPin} style={{ backgroundColor: tPin ? '#F7931A' : '#D3D3D3', width: '290px' }}>Verify</button>
                                    </form>
                                </div>
                            ) : (
                                <div>
                                    <h2 className='mt-10 text-center'>Create Your Transaction Pin</h2>
                                    <form onSubmit={handleCreateTpin} className='flex flex-col justify-center items-center gap-1 mt-4 mb-8'>
                                        <input
                                            type="text"
                                            name='tPin'
                                            value={tPin}
                                            onChange={handleTpinChange}
                                            maxLength={5}
                                            minLength={5}
                                            className='mobile-number-input'
                                        />
                                        {error && <p className='mt-4 text-center text-red-600'>{error}</p>}
                                        <button type="submit" className='primary-button primary-btn mt-4' disabled={!tPin} style={{ backgroundColor: tPin ? '#F7931A' : '#D3D3D3', width: '290px' }}>Create</button>
                                    </form>
                                </div>
                            )
                        }
                    </>
                )
            }
            {
                showContent && (
                    <>
                        <div className='pl-4 mt-16'>
                            <div className='text-center' style={{ fontSize: '0.9rem', color: '#333' }}>
                                <span>Wallet amount : </span>
                                <span>{formatCurrency(user?.totalBonus || 0)}</span>
                            </div>
                            <div className='mt-8'>
                                <span style={{ color: '#343A40' }}>Enter recipient mobile number</span>
                                <InputGroup className="mb-3 payment-wrap mt-2">
                                    <Form.Control
                                        placeholder="Mobile number"
                                        aria-label="Mobile numbert"
                                        aria-describedby="basic-addon2"
                                        name='mobileNumber'
                                        value={mobileNumber}
                                        className=''
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                            <div className='mt-4'>
                                <span style={{ color: '#343A40' }}>Enter amount</span>
                                <InputGroup className="mb-5 payment-wrap mt-2">
                                    <Form.Control
                                        placeholder="Amount"
                                        aria-label="Amount"
                                        aria-describedby="basic-addon2"
                                        name='amount'
                                        value={amount}
                                        className=''
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        {error && <div style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>*{error}</div>}
                        <div className='mt-4 pl-4'>
                            <InputGroup className="mb-3 mt-2 share" onClick={handleSubmit} style={{ backgroundColor: amount ? '#0063F5' : '#D3D3D3', width: '290px' }}>
                                <Button variant="outline-secondary" id="button-addon2">
                                    Transfer Money
                                </Button>
                            </InputGroup>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default TransferWalletAmount;