import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import Swal from 'sweetalert2';
import qs from 'qs';
import imageCompression from 'browser-image-compression';
import './Profile.css';
import { uploadImageToCloudinary } from '../../helper';
import Spinner from '../Loader/Spinner';

const Profile = ({ API_URL, setIsVerified, token, referdBy }) => {
    const [profileInfo, setProfileInfo] = useState({
        fullName: '',
        photo: null,
    });

    const [personalInfo, setPersonalInfo] = useState({
        panNumber: '',
        aadharNumber: '',
    });

    const [bankDetails, setBankDetails] = useState({
        bankName: '',
        accountNumber: '',
        confirmAccountNumber: '',
        ifscCode: '',
        accountHolderName: '',
        scanner: null,
        upiId: '',
    });

    const profilePhotoRef = useRef(null);
    const scannerPhotoRef = useRef(null);
    const [photoPreview, setPhotoPreview] = useState(null); // State for preview
    const [scannerPreview, setScannerPreview] = useState(null); // State for scanner preview
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token])

    useEffect(() => {
        const mobile = localStorage.getItem('mobile');
        const fetchUser = async (mobile) => {
            try {
                const res = await axios.get(`${API_URL}/api/profile/get-user-details?mobile=${mobile}`);
                if (res.data?.user) {
                    const user = res.data?.user;

                    // Populate the state with user details
                    setProfileInfo({
                        fullName: user.fullName || '', // Fallback to an empty string if data is null/undefined
                        photo: user.photo || null,
                    });

                    setPersonalInfo({
                        panNumber: user.panNumber || '',
                        aadharNumber: user.aadharNumber || '',
                    });

                    setBankDetails({
                        bankName: user.bankName || '',
                        accountNumber: user.accountNumber || '',
                        confirmAccountNumber: user.accountNumber || '', // Pre-fill confirm field if available
                        ifscCode: user.ifscCode || '',
                        accountHolderName: user.accountHolderName || '',
                        upiId: user.upiId || '',
                    });

                    // Set previews if photo or scanner exists
                    if (user.photo) setPhotoPreview(user.photo);
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
        fetchUser(mobile);
    }, [API_URL, token])


    // Update handlers for each section
    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setProfileInfo({ ...profileInfo, [name]: value });
    };

    const handlePersonalInfoChange = (e) => {
        const { name, value } = e.target;
        // Automatically convert the PAN number to uppercase
        if (name === 'panNumber') {
            setPersonalInfo({ ...personalInfo, [name]: value.toUpperCase() });
        } else {
            setPersonalInfo({ ...personalInfo, [name]: value });
        }
    };

    const handleBankDetailsChange = (e) => {
        const { name, value } = e.target;

        if (name === 'ifscCode') {
            setBankDetails({ ...bankDetails, [name]: value.toUpperCase() });
        } else {
            setBankDetails({ ...bankDetails, [name]: value });
        }
    };

    // File input handlers
    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfileInfo({ ...profileInfo, photo: file });
            // Generate a preview URL for the selected photo
            setPhotoPreview(URL.createObjectURL(file));
        }
    };

    const handleScannerChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setBankDetails({ ...bankDetails, scanner: file });
            // Generate a preview URL for the selected scanner image
            setScannerPreview(URL.createObjectURL(file));
        }
    };

    const handleFileUploadClick = () => {
        profilePhotoRef.current.click();
    };

    const handleScannerUploadClick = () => {
        scannerPhotoRef.current.click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('authToken');
        setLoading(true);
        if (!token) {
            navigate('/');
            return;
        }

        const formData = new FormData();

        // Compress and upload profile photo to Cloudinary
        let profilePhotoUrl = null;
        // if (profileInfo.photo) {
        //     try {
        //         const compressedProfilePhoto = await compressImage(profileInfo.photo);
        //         profilePhotoUrl = await uploadImageToCloudinary(compressedProfilePhoto); // Function that uploads to Cloudinary
        //     } catch (error) {
        //         console.error('Error uploading profile photo:', error);
        //         profilePhotoUrl = null;
        //     }
        // }
        formData.append('fullName', profileInfo.fullName);
        // formData.append('photo', profilePhotoUrl); // Append Cloudinary photo URL

        // Append personal info
        formData.append('panNumber', personalInfo.panNumber);
        formData.append('aadharNumber', personalInfo.aadharNumber);

        // Compress and upload scanner document to Cloudinary
        // let scannerPhotoUrl = null;
        // if (bankDetails.scanner) {
        //     try {
        //         const compressedScannerPhoto = await compressImage(bankDetails.scanner);
        //         scannerPhotoUrl = await uploadImageToCloudinary(compressedScannerPhoto); // Upload scanner document to Cloudinary
        //     } catch (error) {
        //         console.error('Error uploading scanner photo:', error);
        //         scannerPhotoUrl = null;
        //     }
        // }

        // Append bank details
        formData.append('bankName', bankDetails.bankName);
        formData.append('accountNumber', bankDetails.accountNumber);
        formData.append('confirmAccountNumber', bankDetails.confirmAccountNumber);
        formData.append('ifscCode', bankDetails.ifscCode);
        formData.append('accountHolderName', bankDetails.accountHolderName);
        // formData.append('scanner', scannerPhotoUrl); // Append Cloudinary scanner URL

        // Check if account numbers match
        if (bankDetails.accountNumber !== bankDetails.confirmAccountNumber) {
            return Swal.fire({
                icon: 'error',
                title: 'Bank Details Mismatch',
                text: 'Account number does not match!',
                confirmButtonColor: '#F7931A'
            });
        }
        setLoading(false);

        // Confirm before submission
        const confirmation = await Swal.fire({
            icon: 'warning',
            title: 'Profile Updation',
            text: 'Check all the details carefully before submission!',
            showCancelButton: true,
            confirmButtonColor: '#F7931A',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, submit it!'
        });

        if (!confirmation.isConfirmed) {
            return;
        }

        try {
            const data = {
                fullName: profileInfo.fullName,
                photo: profilePhotoUrl,
                panNumber: personalInfo.panNumber,
                aadharNumber: personalInfo.aadharNumber,
                bankName: bankDetails.bankName,
                accountNumber: bankDetails.accountNumber,
                ifscCode: bankDetails.ifscCode,
                accountHolderName: bankDetails.accountHolderName,
                // scanner: scannerPhotoUrl,
                upiId: bankDetails.upiId,
                referdBy: referdBy
            };
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const res = await axios.post(`${API_URL}/api/profile/create`,
                qs.stringify(data),
                config
            );

            // Success notification
            Swal.fire({
                icon: 'success',
                title: 'Profile Updated',
                text: 'Your profile has been updated successfully!',
                confirmButtonColor: '#F7931A'
            }).then(() => {
                if (res.status == 200) {
                    setIsVerified(true);
                    window.location.reload();
                    navigate('/');
                } else {
                    navigate('/');
                }
            });
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to update profile. Please try again!',
                confirmButtonColor: '#F7931A'
            });
            setLoading(false);
        }
    };

    const compressImage = async (imageFile) => {
        const options = {
            maxSizeMB: 1, // Maximum size in MB
            maxWidthOrHeight: 800, // Maximum width or height
            useWebWorker: true, // Use a web worker for better performance
        };
        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.error('Error compressing image:', error);
            throw error;
        }
    };

    return (
        <>
            {
                loading ? (
                    <Spinner />
                ) : (
                    <form className='profile-form' onSubmit={handleSubmit}>
                        <div className="space-y-12">
                            <div className="border-b border-gray-900/10 pb-12 profile-wrapper">
                                <h2 className="text-base font-semibold leading-7 text-gray-900 mt-8">Profile</h2>
                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="fullName" className="block text-md font-medium leading-6 text-gray-900">
                                            Full Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="fullName"
                                                name="fullName"
                                                type="text"
                                                placeholder=""
                                                autoComplete="fullName"
                                                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-inset sm:text-sm sm:leading-6 full-name-input"
                                                value={profileInfo.fullName}
                                                onChange={handleProfileChange}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-span-full">
                                        <label htmlFor="photo" className="block text-md font-medium leading-6 text-gray-900">
                                            Profile Photo
                                        </label>
                                        <div className="mt-2 flex items-center gap-x-3">
                                            {photoPreview ? (
                                                <img src={photoPreview} alt="Profile Preview" className="h-12 w-12 rounded-full object-cover" />
                                            ) : (
                                                <UserCircleIcon aria-hidden="true" className="h-12 w-12 text-gray-300" />
                                            )}                                <button
                                                type="button"
                                                className="rounded-md px-2.5 py-1.5 text-sm font-semibol ring-inset"
                                                style={{ backgroundColor: "#F7931A", borderColor: "#F7931A", color: "#fff", border: "1px solid #F7931A" }}
                                                onClick={handleFileUploadClick}
                                            >
                                                <input id="photo" name="photo" type="file" className="sr-only" ref={profilePhotoRef} // Attach the ref to the hidden file input
                                                    onChange={handlePhotoChange} // Handle file change
                                                    accept="image/*" />
                                                Choose
                                            </button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold base leading-7 text-gray-900">Personal Information</h2>
                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="panNumber" className="block text-sm font-medium leading-6 text-gray-900">
                                            PAN Number
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="panNumber"
                                                name="panNumber"
                                                type="text"
                                                autoComplete="panNumber"
                                                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-inset sm:text-sm sm:leading-6 full-name-input"
                                                required
                                                value={personalInfo.panNumber}
                                                onChange={handlePersonalInfoChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="aadharNumber" className="block text-sm font-medium leading-6 text-gray-900">
                                            Aadhar Number
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="aadharNumber"
                                                name="aadharNumber"
                                                type="text"
                                                autoComplete="aadharNumber"
                                                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-inset sm:text-sm sm:leading-6 full-name-input"
                                                required
                                                value={personalInfo.aadharNumber}
                                                onChange={handlePersonalInfoChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold base leading-7 text-gray-900">Bank Details</h2>
                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <label htmlFor="bankName" className="block text-sm font-medium leading-6 text-gray-900">
                                            Bank Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="bankName"
                                                name="bankName"
                                                type="text"
                                                autoComplete="bankName"
                                                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-inset sm:text-sm sm:leading-6 full-name-input"
                                                required
                                                value={bankDetails.bankName}
                                                onChange={handleBankDetailsChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="accountNumber" className="block text-sm font-medium leading-6 text-gray-900">
                                            Account Number
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="accountNumber"
                                                name="accountNumber"
                                                type="text"
                                                autoComplete="accountNumber"
                                                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-inset sm:text-sm sm:leading-6 full-name-input"
                                                required
                                                value={bankDetails.accountNumber}
                                                onChange={handleBankDetailsChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="confirmAccountNumber" className="block text-sm font-medium leading-6 text-gray-900">
                                            Confirm Account Number <span style={{ color: "red", fontSize: "1.2rem" }}>*</span>
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="confirmAccountNumber"
                                                name="confirmAccountNumber"
                                                type="text"
                                                autoComplete="confirmAccountNumber"
                                                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-inset sm:text-sm sm:leading-6 full-name-input"
                                                required
                                                value={bankDetails.confirmAccountNumber}
                                                onChange={handleBankDetailsChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="ifscCode" className="block text-sm font-medium leading-6 text-gray-900">
                                            IFSC Code
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="ifscCode"
                                                name="ifscCode"
                                                type="text"
                                                autoComplete="ifscCode"
                                                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-inset sm:text-sm sm:leading-6 full-name-input"
                                                required
                                                value={bankDetails.ifscCode}
                                                onChange={handleBankDetailsChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                        <label htmlFor="accountHolderName" className="block text-sm font-medium leading-6 text-gray-900">
                                            Account Holder Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="accountHolderName"
                                                name="accountHolderName"
                                                type="text"
                                                autoComplete="accountHolderName"
                                                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-inset sm:text-sm sm:leading-6 full-name-input"
                                                value={bankDetails.accountHolderName}
                                                onChange={handleBankDetailsChange}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-span-full">
                                        <label htmlFor="scanner" className="block text-md font-medium leading-6 text-gray-900">
                                            Upload Scanner
                                        </label>
                                        <div className="mt-2 flex items-center gap-x-3">
                                            {scannerPreview ? (
                                                <img src={scannerPreview} alt="Scanner Preview" className="h-12 w-12 object-cover" />
                                            ) : (
                                                <PhotoIcon aria-hidden="true" className="h-12 w-12 text-gray-300" />
                                            )}
                                            <button
                                                type="button"
                                                className="rounded-md px-2.5 py-1.5 text-sm font-semibol ring-inset"
                                                style={{ backgroundColor: "#F7931A", borderColor: "#F7931A", color: "#fff", border: "1px solid #F7931A" }}
                                                onClick={handleScannerUploadClick}
                                            >
                                                <input id="scanner" name="scanner" type="file" className="sr-only" ref={scannerPhotoRef} onChange={handleScannerChange} accept="image/*" />
                                                Choose
                                            </button>
                                        </div>
                                    </div> */}
                                    <div className="sm:col-span-3">
                                        <label htmlFor="ifscCode" className="block text-sm font-medium leading-6 text-gray-900">
                                            UPI id
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="upiId"
                                                name="upiId"
                                                type="text"
                                                autoComplete="upiId"
                                                className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-inset sm:text-sm sm:leading-6 full-name-input"
                                                required
                                                value={bankDetails.upiId}
                                                onChange={handleBankDetailsChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6 mb-4">
                            <button
                                type="submit"
                                className="rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                style={{ backgroundColor: "#F7931A", borderColor: "#F7931A", color: "#fff" }}

                            >
                                Save
                            </button>
                        </div>
                    </form>
                )
            }
        </>
    );
};

export default Profile;
