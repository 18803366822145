import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';

const KnowYourPlans = ({ API_URL, token }) => {
    const { assetName } = useParams();
    const [details, setDetails] = useState(null);
    const navigate = useNavigate();

    const handleNav = () => {
        navigate('/profile')
    }

    return (
        <>
            <div className='mt-6 flex flex-wrap flex-row items-center justify-between gap-4 bread-nav' onClick={handleNav}>
                <div className='flex flex-wrap flex-row items-center justify-center gap-4'>
                    <FontAwesomeIcon icon={faAngleLeft} />
                    <div className=''>
                        Back
                    </div>
                </div>
            </div>
            <div className='refer-container flex flex-wrap flex-col justify-center items-center gap-5 mt-3'>
                <div className='flex flex-wrap flex-row justify-center items-center'>
                    <img src="/3k-plan-ex.png" alt="" className='mt-1' />
                </div>
                <div className='flex flex-wrap flex-row justify-center items-center'>
                    <img src="/10k.png" alt="" className='mt-1' />
                </div>
                <div className='flex flex-wrap flex-row justify-center items-center'>
                    <img src="/25k.png" alt="" className='mt-1' />
                </div>
                <div className='flex flex-wrap flex-row justify-center items-center'>
                    <img src="/50k.png" alt="" className='mt-1' />
                </div>
                <div className='flex flex-wrap flex-row justify-center items-center'>
                    <img src="/1L.png" alt="" className='mt-1' />
                </div>
                <h3 className='mt-3 mb-16 text-center font-semibold flex flex-col gap-2' style={{ fontSize: '1rem' }}>
                    <span>
                        *All plans activation are subject to verification and
                        all rights reserved to Mcoins.
                    </span>
                    {/* <br /> */}
                    <span style={{ marginLeft: "-1px" }}>
                        *Each plan validity is 6 Months from the day of activation.
                    </span>
                </h3>
            </div>
        </>
    );
};

export default KnowYourPlans;
