import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import './index.css';

function AboutUs() {
    const navigate = useNavigate();
    const handleNav = () => {
        navigate('/profile');
    };
    return (
        <div className="about-us-container p-4">
            <div className='mt-6 flex flex-wrap flex-row items-center justify-start gap-4 bread-nav' onClick={handleNav}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span>Back</span>
            </div>
            <h1 className="text-2xl font-semibold mb-2">Company Name</h1>
            <p className='mb-2'>MCOINS</p>
            <h2 className="text-lg font-semibold mb-2">Industry</h2>
            <p>Cryptocurrency Trading and Blockchain Technology</p>

            <h2 className="text-lg font-semibold mt-4 mb-2">Founded</h2>
            <p>2019</p>

            <h2 className="text-lg font-semibold mt-4 mb-2">Headquarters</h2>
            <p>Philippines</p>

            <h2 className="text-lg font-semibold mt-4 mb-2">Mission Statement</h2>
            <p>
                To empower individuals and businesses worldwide by providing a secure, transparent,
                and user-friendly platform for cryptocurrency trading and blockchain innovation.
            </p>

            <h2 className="text-lg font-semibold mt-4 mb-2">Vision</h2>
            <p>
                To be a global leader in cryptocurrency trading, driving the adoption of decentralized
                financial systems and enabling financial freedom for all.
            </p>

            <h2 className="text-lg font-semibold mt-4 mb-2">About Us</h2>
            <p>
                MCOIN is a cutting-edge cryptocurrency trading platform that simplifies the digital asset
                trading experience for both beginners and professional traders. We offer an intuitive interface,
                advanced trading tools, and robust security features to ensure seamless and safe transactions.
            </p>
            <p>
                At MCOIN, we prioritize transparency, security, and innovation, striving to make cryptocurrency
                trading accessible to everyone. With a focus on customer satisfaction and a commitment to compliance
                with global regulations, MCOIN is a trusted partner in the rapidly evolving blockchain ecosystem.
            </p>

            <h2 className="text-lg font-semibold mt-4 mb-2">Core Features</h2>
            <ul className="list-disc ml-6">
                <li>Wide Range of Cryptocurrencies: Trade popular cryptocurrencies like Bitcoin, Ethereum, and Litecoin, alongside emerging tokens.</li>
                <li>Advanced Trading Tools: Access real-time market data, charting tools, and automated trading features.</li>
                <li>Secure Wallets: Store your digital assets safely with multi-layer encryption and cold storage solutions.</li>
                <li>Low Fees: Enjoy competitive trading fees and transparent pricing.</li>
                <li>Educational Resources: Learn about cryptocurrency trading through webinars, tutorials, and market insights.</li>
                <li>Mobile App: Trade on-the-go with our user-friendly mobile application, MCOIN.</li>
            </ul>

            <h2 className="text-lg font-semibold mt-4 mb-2">Why Choose MCOIN?</h2>
            <ul className="list-disc ml-6">
                <li>Security First: With two-factor authentication (2FA), SSL encryption, and regular audits, MCOIN ensures the safety of user funds and data.</li>
                <li>User-Centric Design: Our platform is designed to provide a seamless trading experience for users of all skill levels.</li>
                <li>24/7 Customer Support: Our dedicated support team is available around the clock to assist users with any queries.</li>
                <li>Regulatory Compliance: MCOIN adheres to global regulatory standards, fostering trust and reliability.</li>
                <li>Global Reach: Serving traders from all over the world, MCOIN bridges the gap between traditional and decentralized finance.</li>
            </ul>

            <h2 className="text-lg font-semibold mt-4 mb-2">Partnerships and Collaborations</h2>
            <p>
                MCOIN collaborates with leading blockchain companies, financial institutions, and regulatory bodies to drive innovation
                and promote the adoption of digital assets globally.
            </p>

            <h2 className="text-lg font-semibold mt-4 mb-2">Contact Information</h2>
            <p>Address: Embassy Tech Square Main Rd, Kaverappa Layout, Kadubeesanahalli, Kadabeesanahalli, Bengaluru, Karnataka 560103</p>
            <p className='mb-28'>Email: <a href="mailto:help.mcoins@protonmail.com" className="text-blue-500">help.mcoins@protonmail.com</a></p>
        </div>
    );
}

export default AboutUs;
