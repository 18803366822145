import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import './index.css';
import Spinner from '../Loader/Spinner';
import Popup from '../Popup';
import Modal from '../Modal';

const UserPortfolio = ({ API_URL, token, user }) => {
    const [loading, setLoading] = useState(true);
    const [investedAmount, setInvestedAmount] = useState(0); // Total invested amount
    const [holdingValue, setHoldingValue] = useState(0); // Wallet/holding value
    const [referralBonus, setReferralBonus] = useState(0); // Referral bonus
    const [planModalShow, setPlanModalShow] = useState(false);

    const navigate = useNavigate();

    const fetchData = async () => {
        setLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const [plansResponse, userResponse] = await Promise.all([
                axios.get(`${API_URL}/api/plan/user-confirmed-plans`, config),
                axios.get(`${API_URL}/api/profile/get-user-details?mobile=${user?.mobile}`, config), // Ensure this endpoint returns user bonus info
            ]);

            if (plansResponse.status === 200 && plansResponse.data.plans) {
                const plans = plansResponse.data.plans || [];
                const totalInvestment = plans.reduce((total, plan) => {
                    return total + (Number(plan.purchasedAmount) || 0);
                }, 0);
                setInvestedAmount(totalInvestment);
            }

            if (userResponse.status === 200 && userResponse.data.user) {
                const { totalBonus, totalReferralBonus } = userResponse.data.user;
                setHoldingValue(totalBonus || 0);
                setReferralBonus(totalReferralBonus || 0);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [API_URL, token]);

    useEffect(() => {
        setPlanModalShow(true);
    }, []);

    const handleReset = async () => {
        setLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await axios.get(`${API_URL}/api/plan/reset-bonus`, config);
            if (response.status === 200) {
                console.log('Bonus reset successfully');
                await fetchData(); // Refresh data after reset
            }
        } catch (error) {
            console.error('Error resetting bonus:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const formatCurrency = (value) => {
        const numValue = Number(value);
        return isNaN(numValue)
            ? '0.00'
            : numValue.toLocaleString('en-IN', { minimumFractionDigits: 2 });
    };

    const calculatePercentageDifference = (holdingValue, investmentValue) => {
        if (!holdingValue || holdingValue === 0 || !investmentValue) return '0.00';
        return ((holdingValue / investmentValue) * 100).toFixed(2);
    };

    const handleWithdrawal = () => {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            html: `Withdrawal service is temporarily suspended. Please be with us. We're trying to serve you our best.`,
            confirmButtonColor: '#F7931A'
        });
    }

    return (
        <div className='portfolio-wrap'>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <div className='dash-banner'>
                        <div className='banner-item-0'>
                            <div className='flex flex-wrap flex-row items-center justify-center gap-4'>
                                <h3 className='mb-2' style={{ fontSize: '1.3rem' }}>Portfolio</h3>
                                <div onClick={fetchData} title='Refresh your data'>
                                    <img src="/refresh.png" alt="Refresh" />
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: '0.8rem' }}>Wallet amount</div>
                                <div style={{ fontSize: '1.3rem' }}>
                                    <FontAwesomeIcon icon={faIndianRupeeSign} />
                                    <span className='ml-1'>{formatCurrency(holdingValue)}</span>
                                    <span className='ml-6' style={{ fontSize: '0.8rem' }}>
                                        {calculatePercentageDifference(holdingValue, investedAmount)}%
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='banner-item-0 flex flex-wrap flex-row items-center justify-between pr-4 mt-4'>
                            <div className='flex flex-wrap flex-col items-baseline'>
                                <div style={{ fontSize: '0.8rem' }}>Investment</div>
                                <div style={{ fontSize: '0.8rem' }}>
                                    <FontAwesomeIcon icon={faIndianRupeeSign} />
                                    <span className='ml-1'>{formatCurrency(investedAmount)}</span>
                                </div>
                            </div>
                            <div className='flex flex-wrap flex-col items-baseline justify-center'>
                                <div style={{ fontSize: '0.8rem' }}>Referral Bonus</div>
                                <div style={{ fontSize: '0.8rem' }}>
                                    <FontAwesomeIcon icon={faIndianRupeeSign} />
                                    <span className='ml-1'>{formatCurrency(referralBonus)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap flex-row items-center gap-6 mt-4'>
                        <div className='tuwi-btn-wrap tu' onClick={() => navigate('/our-plans')}>
                            <button>Top up</button>
                        </div>
                        {/* <div className='tuwi-btn-wrap' onClick={() => navigate('/withdraw')}>
                            <button>Withdraw</button>
                        </div> */}
                        <div className='tuwi-btn-wrap' onClick={handleWithdrawal}>
                            <button>Withdraw</button>
                        </div>
                    </div>
                    <div className='flex flex-wrap flex-row items-center justify-between mt-8 mb-6' onClick={() => navigate('/transaction-history')}>
                        <div className='flex flex-wrap flex-row items-center'>
                            <img src='/icons-clock-right.png' className='th-img' alt="Transaction History" />
                            <span className='th-text ml-3'>Transaction History</span>
                        </div>
                        <FontAwesomeIcon icon={faAngleRight} style={{ color: '#999999' }} />
                    </div>
                    <hr />
                    <div className='flex flex-wrap flex-row items-center justify-between mt-8 mb-4' onClick={() => navigate('/your-plans')}>
                        <div className='flex flex-wrap flex-row items-center'>
                            <img src='/icons8-grid-2.png' className='th-img' alt="Plan Details" />
                            <span className='th-text ml-3'>Plan Details</span>
                        </div>
                        <FontAwesomeIcon icon={faAngleRight} style={{ color: '#999999' }} />
                    </div>
                    <hr />
                    <div className='flex flex-wrap flex-row items-center justify-between mt-8 mb-4' onClick={() => navigate('/wallet-details')}>
                        <div className='flex flex-wrap flex-row items-center'>
                            <img src='/wallet-32.png' className='th-img' alt="Plan Details" />
                            <span className='th-text ml-3'>Wallet Transactions</span>
                        </div>
                        <FontAwesomeIcon icon={faAngleRight} style={{ color: '#999999' }} />
                    </div>
                    <hr />
                    <div className='flex flex-wrap flex-row items-center justify-between mt-8 mb-4' onClick={() => navigate('/referal-details')}>
                        <div className='flex flex-wrap flex-row items-center'>
                            <img src='/refferal-details.png' className='th-img' alt="Referral Details" />
                            <span className='th-text ml-3'>Referral Details</span>
                        </div>
                        <FontAwesomeIcon icon={faAngleRight} style={{ color: '#999999' }} />
                    </div>
                    {/* <hr /> */}
                    {/* <div className='mt-6' style={{ fontSize: '0.7rem', color: 'red' }}>*Due to scheduled maintenance activity some of our features might not be available this week. But don't worry and relax since we are improving on our services and committed to serve you the best experience.</div> */}
                </>
            )}
            <Modal />
            {/* <Popup show={planModalShow} onHide={() => setPlanModalShow(false)}>
                <img src="/refer_modal.jpeg" alt="Referral Modal" className="modal-image" />
            </Popup> */}
        </div>
    );
};

export default UserPortfolio;
