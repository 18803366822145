import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { AiOutlineStar } from "react-icons/ai";
import { LineChart, Line, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

const PortfolioDetails = ({ API_URL, token }) => {
    const { assetName } = useParams();
    const [details, setDetails] = useState(null);
    const { id } = useParams(); // Access the 'id' parameter from the route

    const navigate = useNavigate();

    const handleNav = () => {
        if (id === 'id=user-plans') {
            navigate('/portfolio')
        } else {
            navigate('/')
        }
    }

    const handlePlan = (asset, plan) => {
        navigate(`/deposit/${plan}`);
    }

    return (
        <>
            <div className='mt-6 flex flex-wrap flex-row items-center justify-between gap-4 bread-nav' onClick={handleNav}>
                <div className='flex flex-wrap flex-row items-center justify-center gap-4'>
                    <FontAwesomeIcon icon={faAngleLeft} />
                    <div className=''>
                        Back
                    </div>
                </div>
            </div>
            <div className='refer-container flex flex-wrap flex-col justify-center items-center gap-5 mt-3'>
                <div className='flex flex-wrap flex-row justify-center items-center' onClick={() => handlePlan(details?.id, 'standard-saving-plan')}>
                    <img src="/Plan1.png" alt="" className='mt-1' />
                </div>
                <div className='flex flex-wrap flex-row justify-center items-center' onClick={() => handlePlan(details?.id, 'super-saving-plan')}>
                    <img src="/Plan2.png" alt="" className='mt-1' />
                </div>
                <div className='flex flex-wrap flex-row justify-center items-center' onClick={() => handlePlan(details?.id, 'bumper-saving-plan')}>
                    <img src="/Plan3.png" alt="" className='mt-1' />
                </div>
                <div className='flex flex-wrap flex-row justify-center items-center mb-16' onClick={() => handlePlan(details?.id, 'ultimate-saving-plan')}>
                    <img src="/Plan4.png" alt="" className='mt-1' />
                </div>
            </div>
        </>
    );
};

export default PortfolioDetails;
