import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import qs from 'qs';

const DepositPlan = ({ API_URL, user }) => {
    const { asset, plan } = useParams();
    const [planName, setPlanName] = useState('');
    const [planPrice, setPlanPrice] = useState('');
    const [amount, setAmount] = useState('');  // Track the entered amount
    const [errorMessage, setErrorMessage] = useState('');  // For displaying validation messages
    const [transactionData, setTransactionData] = useState({});
    const [isLoading, setIsLoding] = useState(false);
    const navigate = useNavigate();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNav = () => {
        navigate(`/our-plans`);
    }

    useEffect(() => {
        if (plan === 'standard-saving-plan') {
            setPlanName('Standard Saving Plan');
            setPlanPrice('1000 - 24000');
        } else if (plan === 'super-saving-plan') {
            setPlanName('Super Saving Plan');
            setPlanPrice('25000 - 49000');
        } else if (plan === 'bumper-saving-plan') {
            setPlanName('Bumper Saving Plan');
            setPlanPrice('50,000 - 99000');
        } else if (plan === 'ultimate-saving-plan') {
            setPlanName('Ultimate Saving Plan');
            setPlanPrice('1,00,000 - *depends on you');
        }
    }, [plan]);

    const handleAmountChange = (e) => {
        const enteredAmount = parseInt(e.target.value, 10);

        const [lowerBound, upperBound] = planPrice.replace(/[^\d-]/g, '').split('-').map(num => parseInt(num, 10));

        if (enteredAmount % 1000 !== 0) {
            setErrorMessage('Amount should be in multiples of 1000');
        }
        else if (enteredAmount < lowerBound || (upperBound && enteredAmount > upperBound)) {
            setErrorMessage(`Amount should be between ${lowerBound} and ${upperBound || 'unlimited'}`);
        } else {
            setErrorMessage('');
        }

        setAmount(enteredAmount);
    }

    const openPayment = () => {
        if (!errorMessage && amount) {
            navigate(`/payment/${plan}/${amount}`);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('authToken');

        if (!token) {
            navigate('/');
            return;
        }

        // Parse the range
        const [minRange, maxRange] = planPrice.split(' - ').map(value => parseInt(value.replace(/,/g, '')));

        setIsLoding(true);
        let transactionImage = null;

        // Proceed with the API call if validation passes
        try {
            const data = {
                name: planName,
                range: planPrice,
                purchasedAmount: transactionData && transactionData.purchasedAmount ? transactionData.purchasedAmount : amount,
                transactionId: transactionData && transactionData.transactionId ? transactionData.transactionId : '',
                transactionDate: transactionData && transactionData.transactionDate ? transactionData.transactionDate : '',
                transactionTime: transactionData && transactionData.transactionTime ? transactionData.transactionTime : '',
                transactionImage: transactionImage,
            };

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const res = await axios.post(`${API_URL}/api/plan/create-using-wallet`,
                qs.stringify(data),
                config
            );

            if (res.status === 200) {
                setIsLoding(false);
            }

            Swal.fire({
                icon: 'success',
                title: 'Plan Purchased',
                text: 'Your plan has been purchased successfully!',
                confirmButtonColor: '#F7931A'
            }).then(() => {
                let timerInterval;

                // Show loader with a countdown timer for 4 seconds
                Swal.fire({
                    title: 'Redirecting...',
                    html: 'Redirecting in <b></b> seconds.',
                    timer: 4000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    willOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector('b');
                        timerInterval = setInterval(() => {
                            b.textContent = Math.ceil(Swal.getTimerLeft() / 1000);
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then(() => {
                    if (res.status === 200) {
                        navigate(`/portfolio`);
                    } else {
                        navigate('/');
                    }
                });
            });


        } catch (err) {
            setIsLoding(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `${err?.response?.data?.message || 'Something went wrong. Please try again!'}`,
                confirmButtonColor: '#F7931A'
            });
        }
    };

    const formatCurrency = (value) => {
        const numValue = Number(value); // Convert value to a number
        if (isNaN(numValue)) {
            return '0.00'; // Fallback if value is not a number
        }
        return numValue.toLocaleString('en-IN', { minimumFractionDigits: 2 });
    };

    const formattedHoldingValue = formatCurrency(parseFloat(user?.totalBonus || 0));
    const holdingValue = parseFloat(user?.totalDailyBonus || 0) + parseFloat(user?.totalReferralBonus || 0);
    return (
        <div className='deposit-container' style={{ margin: windowHeight < 700 ? '1rem auto 6rem auto' : '1rem auto auto auto' }}>
            <div className='mt-6 flex flex-wrap flex-row items-center justify-between gap-4 bread-nav' onClick={handleNav}>
                <div className='flex flex-wrap flex-row items-center justify-center'>
                    <FontAwesomeIcon icon={faAngleLeft} />
                    <div className='ml-4'>
                        {planName}
                    </div>
                </div>
            </div>
            <div className='text-center' style={{ fontSize: '0.9rem', color: '#333' }}>
                <span>Wallet amount : </span>
                <span>{formattedHoldingValue}</span>
            </div>
            <div className='flex flex-wrap flex-row items-center justify-center mt-12'>
                <span style={{ color: '#999999', fontSize: '0.8rem' }}>Enter amount in INR</span>
                <div style={{ color: '#212529', fontSize: '1.5rem' }} className='amount-wrapper mt-4 flex flex-wrap flex-row items-center justify-center'>
                    <span>
                        <FontAwesomeIcon icon={faIndianRupeeSign} />
                    </span>
                    <input
                        type='number'
                        inputMode='numeric'
                        pattern="[0-9]*"
                        placeholder='0'
                        className='amount-input ml-1'
                        value={amount || ''}
                        onChange={handleAmountChange}
                    />
                </div>
                {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
            </div>
            <div className='mt-12' style={{ color: '#343A40' }}>
                <div>* Deposit amount should be in multiple of <span><FontAwesomeIcon icon={faIndianRupeeSign} /></span>1000</div>
                <div>* This Plan is in between <span><FontAwesomeIcon icon={faIndianRupeeSign} /></span>{planPrice}</div>
            </div>
            <div className='deposit-btn db-bg mt-10' style={{ backgroundColor: amount ? '#0063F5' : '#D3D3D3' }} onClick={openPayment}>
                <button>Deposit INR</button>
            </div>
            {
                amount < holdingValue && (
                    <div className='flex flex-col flex-wrap mt-4 justify-center items-center'>
                        <div className='mb-4 font-semibold' style={{ fontSize: '1.2rem' }}>OR</div>
                        <div className='flex flex-wrap flex-row justify-around gap-4'>
                            <div className='wallet-img'>
                                <img src="/wallet_8305548.png" alt="wallet" />
                            </div>
                            <div className='mt-2 deposit-btn' onClick={handleSubmit} style={{ backgroundColor: '#0063F5' }}>
                                <span style={{ fontSize: '1rem', color: '#fff' }}>Instead use wallet amount for Top-up</span>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default DepositPlan;
