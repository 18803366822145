'use client'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import './Modal.css';

export default function Modal() {
    const [open, setOpen] = useState(true)
    const navigate = useNavigate();

    const handleNav = () => {
        navigate(`/refer-n-earn/id=portfolio`);
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className='flex flex-wrap justify-end mr-4 mt-4' onClick={() => setOpen(false)}
                        >
                            <img src="/close.png" alt="" />
                        </div>
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4" onClick={handleNav}>
                            <div className="sm:flex sm:items-start">
                                {/* <img src="/refer_modal.jpeg" alt="Referral Modal" className="modal-image" /> */}
                                Urgent Update: Temporary Business Closure <br /><br />
                                Due to a massive fire outbreak in California, Our office is completely burndown, all operations including withdrawals are temporarily suspended to ensure the safety of our staff and community.<br />
                                In this period your Daily Bonus and Referral Bonus will not be credited and withdrawal will not be functional .We deeply apologize for any inconvenience caused and will keep you updated as the situation unfolds. <br /><br />Thank you for your understanding. Stay safe!
                            </div>
                        </div>

                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}
