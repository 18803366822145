import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Login from './components/Login/Login';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import Footer from './components/Footer';
import Rewards from './components/Rewards';
import UserProfile from './components/UserProfile';
import Market from './components/Market';
import Portfolio from './components/Portfolio';
import ReferAndEarn from './components/ReferAndEarn';
import PortfolioDetails from './components/Portfolio/PortfolioDetails';
import DepositPlan from './components/Portfolio/DepositPlan';
import PaymentPlan from './components/Portfolio/PaymentPlan';
import Spinner from './components/Loader/Spinner';
import UserPortfolio from './components/UserPortfolio';
import UserPlans from './components/UserPlans';
import TransactionTabs from './components/TransactionHistory';
import WithdrawPayment from './components/WithdrawPayment';
import HelpAndSupport from './components/HelpAndSupport';
import Privacy from './components/Privacy';
import TermAndConditions from './components/TermsAndConditions';
import KnowAboutReferral from './components/KnowAboutReferral';
import ReferralDetails from './components/ReferralDetails';
import KnowYourPlans from './components/KnowYourPlans';
import CompletePayment from './components/UserPlans/CompletePayment';
import AboutUs from './components/AboutUs';
import TransferWalletAmount from './components/UserPlans/TransferWalletAmount';
import WalletDetails from './components/WalletDetails';

const App = () => {
  const [authToken, setAuthToken] = useState('');
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : '';

  return (
    <Router>
      <AppRoutes API_URL={API_URL} />
    </Router>
  );
};

const AppRoutes = ({ API_URL }) => {
  const navigate = useNavigate();
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const [refCode, setRefCode] = useState('');
  const [fullName, setFullName] = useState('');
  const token = localStorage.getItem('authToken');
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [referdBy, setReferedBy] = useState('');
  const [tPin, setTpin] = useState('');
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    // Check URL parameters for refCode
    const params = new URLSearchParams(window.location.search);
    const urlRefCode = params.get('refCode');
    // console.log({ urlRefCode });
    if (!token && urlRefCode) {
      // Store refCode in localStorage if user is not logged in
      localStorage.setItem('referedBy', urlRefCode);
      setReferedBy(urlRefCode); // Set state to use later if needed
    }
  }, [token]);

  useEffect(() => {
    if (!isOnline) {
      setLoading(false);
      return;
    }
    const fetchData = async () => {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      const mpin = localStorage.getItem('mpin');
      const mobile = localStorage.getItem('mobile');

      try {
        const res = await axios.get(`${API_URL}/api/profile/get?mpin=${mpin}&mobile=${mobile}`);

        if (res.data?.user) {
          const { aadharNumber, accountHolderName, accountNumber, bankName, fullName, ifscCode, panNumber } = res.data.user;
          if (aadharNumber && accountHolderName && accountNumber && bankName && fullName && ifscCode && panNumber) {
            setIsProfileCompleted(true);
            setRefCode(res.data.user.referalCode);
            setFullName(res.data.user.fullName);
            setUser(res.data.user);
            setTpin(res.data.user.tPin);
          }
          setLoading(false);
        } else {
          localStorage.removeItem('authToken');
          // window.location.reload();
          setIsProfileCompleted(false);
          setLoading(false);
        }
      } catch (error) {
        setIsProfileCompleted(false);
        setLoading(false);
        navigate('/login');
      }
    };

    fetchData();
  }, [navigate, token]);

  // useEffect(() => {
  //   const fetchDailyEarnings = async () => {
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     };

  //     try {
  //       await axios.get(`${API_URL}/api/plan/daily-earnings`, config);
  //     } catch (error) {
  //       navigate('/');
  //     }
  //   };

  //   fetchDailyEarnings();
  // }, [API_URL, token])

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [token]);

  const handleLogout = () => {
    setLoading(true);

    // Perform logout actions
    setIsProfileCompleted(false);
    setUser(false);
    setFullName(false);
    setIsLoggedIn(false);
    localStorage.removeItem('authToken');

    // Wait for 3 seconds before navigating
    setTimeout(() => {
      // Hide loader
      setLoading(false);
      // Navigate to the login page
      navigate('/login');
    }, 4000); // 4 seconds delay
  }

  if (loading) {
    return <Spinner />; // Show spinner while token check is in progress
  }

  return (
    <>
      {!isOnline && <div className="offline-alert text-center mt-20">You are offline. Please check your connection.</div>}

      <Routes>
        {
          !token ? (
            <Route
              path="/login"
              element={
                <Login
                  isProfileCompleted={isProfileCompleted}
                  setIsVerified={setIsVerified}
                  setIsProfileCompleted={setIsProfileCompleted}
                  API_URL={API_URL}
                  token={token}
                  user={user}
                />
              }
            />
          ) : (
            <>
              {/* <Route
                path="/login"
                element={<Login isProfileCompleted={isProfileCompleted} setIsVerified={setIsVerified} setIsProfileCompleted={setIsProfileCompleted} API_URL={API_URL} token={token} />}
              /> */}
              <Route
                path="/"
                element={
                  loading ? (
                    <div>
                      <Spinner />
                    </div>
                  ) : (
                    isProfileCompleted ? (
                      <Dashboard fullName={fullName} token={token} API_URL={API_URL} />
                    ) : (
                      <Profile API_URL={API_URL} setIsVerified={setIsVerified} token={token} referdBy={referdBy} />
                    )
                  )
                }
              />
              <Route path="/portfolio" element={<UserPortfolio API_URL={API_URL} token={token} user={user} />} />
              <Route path="/top-portfolio" element={<Portfolio token={token} API_URL={API_URL} />} />
              <Route path="/rewards" element={<Rewards API_URL={API_URL} token={token} />} />
              <Route path="/portfolio/:assetName" element={<PortfolioDetails API_URL={API_URL} token={token} />} />
              <Route path="/our-plans" element={<PortfolioDetails API_URL={API_URL} token={token} />} />
              <Route path="/our-plans/:id" element={<PortfolioDetails API_URL={API_URL} token={token} />} />
              <Route
                path="/profile"
                element={
                  loading ? (
                    <div>
                      <Spinner />
                    </div>
                  ) : (
                    isProfileCompleted ? <UserProfile API_URL={API_URL} user={user} handleLogout={handleLogout} setIsVerified={setIsVerified} /> : <Profile API_URL={API_URL} setIsVerified={setIsVerified} token={token} referdBy={referdBy} />
                  )
                }
              />
              <Route path="/refer-n-earn/:id" element={<ReferAndEarn refCode={refCode} API_URL={API_URL} token={token} />} />
              <Route path="/refer-n-earn" element={<ReferAndEarn refCode={refCode} API_URL={API_URL} token={token} />} />
              <Route path="/deposit/:plan" element={<DepositPlan API_URL={API_URL} user={user} />} />
              <Route path="/payment/:plan/:amount" element={<PaymentPlan API_URL={API_URL} token={token} />} />
              <Route path="/market" element={<Market API_URL={API_URL} token={token} />} />
              <Route path="/your-plans/:id" element={<UserPlans API_URL={API_URL} token={token} />} />
              <Route path="/your-plans" element={<UserPlans API_URL={API_URL} token={token} />} />
              <Route path="/complete-payment/:id/:amount" element={<CompletePayment API_URL={API_URL} token={token} />} />
              <Route path="/transaction-history" element={<TransactionTabs API_URL={API_URL} token={token} />} />
              <Route path="/withdraw" element={<WithdrawPayment API_URL={API_URL} token={token} trPin={tPin} setTpin={setTpin} user={user} />} />
              <Route path='/help-and-support' element={<HelpAndSupport API_URL={API_URL} token={token} />} />
              <Route path='/privacy-policy' element={<Privacy API_URL={API_URL} token={token} />} />
              <Route path='/terms-and-conditions' element={<TermAndConditions API_URL={API_URL} token={token} />} />
              <Route path='/know-about-referal' element={<KnowAboutReferral API_URL={API_URL} token={token} />} />
              <Route path='/referal-details' element={<ReferralDetails API_URL={API_URL} token={token} />} />
              <Route path='/know-your-plans' element={<KnowYourPlans API_URL={API_URL} token={token} />} />
              <Route path='/about-us' element={<AboutUs API_URL={API_URL} token={token} />} />
              <Route path='/transfer-wallet-amount' element={<TransferWalletAmount API_URL={API_URL} token={token} user={user} />} />
              <Route path='/wallet-details' element={<WalletDetails API_URL={API_URL} token={token} user={user} />} />

            </>
          )
        }
        < Route path="*" element={
          !token ? (
            <Login isProfileCompleted={isProfileCompleted} setIsVerified={setIsVerified} setIsProfileCompleted={setIsProfileCompleted} API_URL={API_URL} token={token} user={user} />
          ) : (
            <></>
          )
        } />
      </Routes>
      {
        isProfileCompleted ? (
          (isLoggedIn) && <Footer />
        ) : (<></>)
      }
    </>
  );
};

export default App;
