'use client';

import { useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import './Modal.css';

export default function ReferalPlanModal() {
    const [open, setOpen] = useState(true);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    const images = [
        '/referal_plan1.jpeg',
        '/referal_plan2.jpeg',
        '/referal_plan3.jpeg',
        '/referal_plan4.jpeg',
    ];

    const threshold = 50; // Minimum swipe distance to trigger an action

    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStart && touchEnd) {
            const distance = touchStart - touchEnd;
            if (distance > threshold) {
                // Swipe left
                nextSlide();
            } else if (distance < -threshold) {
                // Swipe right
                prevSlide();
            }
        }
        setTouchStart(null);
        setTouchEnd(null);
    };

    const nextSlide = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
            <DialogBackdrop
                className="fixed inset-0 bg-gray-500/75 transition-opacity"
                onClick={() => setOpen(false)}
            />
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <DialogPanel className="relative bg-white rounded-lg shadow-xl w-full max-w-lg p-4">
                        {/* Close Button */}
                        <div className="flex justify-end" onClick={() => setOpen(false)}>
                            <img src="/close.png" alt="Close" className="cursor-pointer" />
                        </div>

                        {/* Carousel */}
                        <div
                            className="relative"
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}
                        >
                            <img
                                src={images[currentImageIndex]}
                                alt={`Slide ${currentImageIndex + 1}`}
                                className="w-full object-cover rounded-md"
                            />
                        </div>

                        {/* Image Indicators */}
                        <div className="flex justify-center mt-4">
                            {images.map((_, index) => (
                                <button
                                    key={index}
                                    className={`w-3 h-3 rounded-full mx-1 ${currentImageIndex === index
                                        ? 'bg-gray-800'
                                        : 'bg-gray-400'
                                        }`}
                                    onClick={() => setCurrentImageIndex(index)}
                                    aria-label={`Go to slide ${index + 1}`}
                                />
                            ))}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
