import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './Popup.css';

const Popup = ({ show, onHide, children }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="popup-modal"
        >
            <Modal.Header closeButton>
                {/* <img
                    src="/close.png"
                    alt="Close"
                    onClick={onHide}
                    className="custom-close-icon"
                /> */}
            </Modal.Header>
            <Modal.Body className="popup-body">
                {children}
            </Modal.Body>
        </Modal>
    );
};

export default Popup;
